import axios from 'axios';
// axios.defaults.baseURL = 'https://dev-hireapi.qrata.ai';
// axios.defaults.baseURL = 'http://localhost:5002';
axios.defaults.baseURL="https://hireapi.qrata.ai"
const instance = axios.create();
instance.interceptors.response.use(response => {return response}, error => {
    console.log("error.response : ", error.response)
    if(error.response.status === 401){
        alert('Your session has expired');
        window.location.replace('/signin');
    }
    if(error.response.status === 429 || error.response.status === 0){
        alert('Too many requests have been called at once... Give the system a minute and refresh again');
        // window.location.replace('/signin');
        return
    }
});

export default instance; 